/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 19:09:41
 * @Module: 消费记录
 */
 <template>
  <div style="background:#fff;overflow:hidden">
    <div style="padding:0 20px 20px">
      <hdqForm :showLabel="['expenseType','empUuid','date']" @search="search" ref="hdqForm" />
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="subject" label="消费功能" min-width="100" />
        <el-table-column label="时间">
          <template slot-scope="scope">{{ scope.row.transactAt|timeFormat('yy-mm-dd hh:MM:ss') }}</template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">{{ scope.row.type|type }}</template>
        </el-table-column>
        <el-table-column prop="payType" label="支付方式">
          <template slot-scope="scope">
            <div v-if="scope.row.payType=='COIN'" class="flex">
              <div>金币</div>
              <img src="@/static/image/icon_jinbi.png" style="margin-left:4px;width:16px;height:16px;" alt="金币">
            </div>
            <div v-else class="flex">
              <div>卡券</div>
              <img src="@/static/image/icon_dianquan.png" style="margin-left:4px;width:16px;height:16px;" alt="卡券">
            </div>
          </template>
          <!-- <template slot-scope="scope">{{ scope.row.payType|payType }}</template> -->
        </el-table-column>
        <el-table-column label="消费">

          <template slot-scope="scope">{{ scope.row.cost||'--' }}{{scope.row.payType=='COIN'?'金币':'卡券'}}</template>
        </el-table-column>
        <el-table-column label="消费账号">
          <template slot-scope="scope">
            <i class="el-icon-ali-xingzhuang1" style="color:#0062FF"></i>
            <span>{{ scope.row.transactorName||'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.note" placement="top" :disabled="!scope.row.note">
              <span @click="()=>open(scope)" class="el-icon-ali-xingzhuangjiehe1" :style="scope.row.note?'':'color:#999;'">
              </span>
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </div>
  </div>
</template>
 <script>

import hdqForm from "@/components/hdqForm.vue";
import hdqPagination from "@/components/hdqPagination.vue"
export default {
  components: {
    hdqForm,
    hdqPagination
  },
  data () {
    return {
      list: []
    };
  },
  mounted () {
    this.getTransaction()
  },
  filters: {
    type (res) {
      if (res === 'CONTRACT') {
        return '合同签订'
      } else if (res === 'EVIDENCE') {
        return '精准取证'
      } else if (res === 'VERIFICATION') {
        return '三要素核查'
      } else if (res === 'SEARCH') {
        return '查小号'
      }
      else {
        return res
      }
    },
    payType (res) {
      if (res === 'COIN') {
        return '金币支付'
      } else if (res === 'BEAN') {
        return '卡券支付'
      } else {
        return res
      }
    }
  },
  methods: {
    search () {
      this.$refs.pagination.createPagination({ current: 1 })
      this.getTransaction()
    },
    paginationChange () {
      this.getTransaction()
    },
    async getTransaction () {
      const { current, size } = this.$refs.pagination
      const { expenseType: type, ...getFormData } = this.$refs.hdqForm.getForm();

      const { status, data } = await this.$api.getTransaction({ current, size, ...getFormData, type })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>